import './css/myprojects.css';
import {Link} from 'react-router-dom';


function MyProjects(){
    

  return (
    <>
      <div className="project-container">
        <div className='project-content'>
          <div className='project-header-and-link'>
            <h2>Avatar <span className="primary-color">Masters</span></h2>
            <a href="/avatarmasters/index.html"><button>View Project</button></a>
          </div>
        <h5>Description:</h5>  
        <p className="secondary-text"> My first game written in pure javascript. 
              I have developed and worked on it over the years. 
              I learned most of the concepts of programming through developing this game. 
              It is a real-time battle simulator where 2 opponents fight each other, 
              using RPG elements and inspired by games like Final Fantasy and Lord of the 
              Rings. It is still a work in progress.</p>    
        </div>
        <div className="img-container">
          <img src="/am.png" alt="game"></img>
        </div>
      </div>
        
      <div className="project-container">
        <div className='project-content'>
          <div className='project-header-and-link'>
            <h2> Zera <span className="primary-color">Games</span></h2>
            <Link to="http://zeragames.com" ><button>View Project</button></Link>
          </div>
          <h5>Description:</h5>  
          <p className="secondary-text"> 
            The dynamic grocery list tool is a project that I developed to solve a common problem 
            faced by many people when shopping for groceries - the difficulty of remembering what 
            ingredients are needed for a single meal or a week's worth of meals. Using my knowledge 
            of programming languages and web development tools, I built a user-friendly interface 
            that allows users to quickly add and remove items from their lists and generate ingredient 
            lists based on specific meal selections. Real-time updates and the ability to save and access 
            previous lists were also incorporated to enhance the user experience. As a developer, I take 
            pride in creating practical solutions that make everyday tasks easier for people. 
            This project is a reflection of my commitment to using technology to solve real-world problems.
          </p>
          
          <p>Use a premade account to try it out!</p>
          <code>
                Username: TestAccount <br></br> Password: password1234
          </code>      
        </div>
        <div className="img-container">
          <img src="/zeragames.png" alt="game"></img>
        </div>
      </div>

      <div className="project-container">
        <div className='project-content'>
          <div className='project-header-and-link'>
            <h2>OSRS <span className="primary-color">Calculator</span></h2>
            {/* <a href="/avatarmasters/index.html"><button>View Project</button></a> */}
          </div>
        <h5>Description:</h5>  
        <p className="secondary-text"> Uses a drag-n-drop modal system that will calculate
        your chances of successfully hitting an opponent. This project shows my experience in
        developing UI systems that are user friendly.</p>    
        </div>
        <div className="img-container">
          <img src="/osrs.png" alt="game"></img>
        </div>
      </div>

    </>
  );
}

export default MyProjects;