import './css/ToggleTheme.css';
import React, { useEffect, useState } from 'react';
import useLocalStorage from 'use-local-storage';

let moonPosition = 0;

function ToggleTheme() {
  const [theme, setTheme] = useLocalStorage('theme', 'dark');
  const [moon, setMoon] = useState('🌕');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    setMoon(theme === 'light' ? '🌑' : '🌕'); 

    const moveMoon = document.getElementById('moon');
    moonPosition = theme == 'light' ? '0' : '16';
    moveMoon.style.left = `${moonPosition}px`;   

  }, [theme]); 

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  return (
    <div className="toggle-theme">
      <button id="button-toggle-theme" onClick={switchTheme}>
        <div id="moon">{moon}</div>
      </button>
    </div>
  );
}

export default ToggleTheme;