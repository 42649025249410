import './css/navigation.css';
import React from 'react';
import { NavLink } from 'react-router-dom';
import ToggleTheme from './ToggleTheme';

function Navigation() {

  return (
    <nav className="navigation">

      <div className="nav-logo">
        Shaun Musick
      </div>

      <ul>
        <NavLink to="/">
          <li className="menu-list-node">
            <i className="fas fa-home"></i>Home
          </li>            
        </NavLink>
        <NavLink to="/projects">
          <li className="menu-list-node">
            <i className="fas fa-folder"></i>Projects
          </li>
        </NavLink>
        <NavLink to="/resume">
          <li className="menu-list-node">
            <i className="fas fa-briefcase"></i>Resume
          </li>
        </NavLink>
        <NavLink to="/about">
          <li className="menu-list-node">
            <i className="fas fa-user-tie"></i>About Me
          </li>
        </NavLink>
        <NavLink to="/contact">
          <li className="menu-list-node">
            <i className="fas fa-mobile"></i>Contact
          </li>
        </NavLink>
      </ul>

      <ToggleTheme />

    </nav>
  );
}

export default Navigation;
