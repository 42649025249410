import Navigation from '../components/navigation';
import Shawn from '../components/shawn';
import MyIntroduction from '../components/myintroduction';
import ParticleCanvas from '../components/particlecanvas';
import Footer from '../components/footer';

function AboutPage() {
  return(
    <div className="App">
      <header className="main-page main-page-organizer set-background">
        <ParticleCanvas />
        <Navigation />
        <Shawn />
        <MyIntroduction />
        <Footer />
      </header>
    </div>
  );
}

export default AboutPage;