import './css/contact.css';
import React from 'react';

function Contact(){
  //Add event listener after render
  React.useEffect(() => {
    document.getElementById('contact-form-container').addEventListener('submit', submitForm);
  }, []);
    

  const submitForm = (event) => {
    event.preventDefault();

    // get values
    let name    = getFormValues('formName');
    let company = getFormValues('formCompany');
    let email   = getFormValues('formEmail');
    let tel     = getFormValues('formTel');
    let message = getFormValues('formMessage');
    
    console.log(name, company, email, tel, message);
  };

  const getFormValues = (id) => {
    return document.getElementById(id).value;
  };


  return (
    <>
      <form id="contact-form-container">
        <div className='formGroup'>
          <div className='formDivider'>
            <label htmlFor="formName" className='formLabel' >Name</label>
            <input id="formName" type='text' name='name' placeholder='Full Name' required></input>
          </div>
          <div className='formDivider'>
            <label htmlFor="formCompany" className='formLabel' >Company</label>
            <input id="formCompany" type='text' name='company' placeholder='Company'></input>
          </div>
        </div>

        <div className='formGroup'>
          <div className='formDivider'>
            <label htmlFor="formEmail" >E-mail</label>
            <input id="formEmail" type='email' name='email' placeholder='E-mail' required></input>
          </div>
          <div className='formDivider'>
            <label htmlFor="formTel" >Phone Number</label>
            <input id="formTel" type='tel' name='phone' pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"placeholder='555-555-5555'></input>
          </div>
        </div>  

        <div className='formGroup'>
          <div className='formDividerMessage'>
            <label htmlFor="formMessage" >Message</label>
            <textarea id="formMessage" type='textarea' name='message' placeholder='Message' required></textarea>
          </div>
        </div> 
        <div className='formGroup'>
          <div className='formDivider'>
            <input id="formSubmit" name='submit' type='submit' value='Send'></input>
          </div>
        </div>
      </form>
    </>
  );
}

export default Contact;