import Navigation from '../components/navigation';
import Contact from '../components/contact';
import Footer from '../components/footer';

function ContactPage() {
  return(
    <div className="App">
      <header className="main-page main-page-organizer set-background">
        <Navigation />
        <Contact />
        <Footer />
      </header>
    </div>
  );
}

export default ContactPage;