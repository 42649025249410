import React from 'react';
import './css/picture-canvas.css';

function PictureCanvas() {

  return (
    <>
      <div id='picture-canvas-container'>
        <div className="example">
          <div className="block">
            <div className="side -main"></div>
            <div className="side -left"></div>
          </div>
          <div className="block">
            <div className="side -main"></div>
            <div className="side -left"></div>
          </div>
          <div className="block">
            <div className="side -main"></div>
            <div className="side -left"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PictureCanvas;