import './css/footer.css';


function Footer(){
    
  return (
    <>
      <footer className="footer">
        <p>Footer 2024</p>
      </footer>
    </>
  );
}

export default Footer;