import './css/shawn.css';
import {Link} from 'react-router-dom';


function Shawn(){
    

  return (
    <>
      <div className="container">
        <img src="/shawn.jpg" alt="shaun musick" />
        <div className="divider"></div>
        <div className="primary-text">Shaun Musick </div>
        <div className="secondary-text">Full-Stack Developer</div>
        <div className="secondary-text">Cyber Security+ <span className="certified">
            &#10003;Certified</span></div>
        <Link to='/contact'><button className='contact'>Contact</button></Link>
        <ul className="footer_container">
          <li className="footer_buttons">
            <i className="fas fa-project-diagram"></i>
            <span className="footer_name"><Link to='/projects'>Projects</Link></span>
          </li>
          <li className="footer_buttons">
            <i className="fas fa-code"></i>
            <span className="footer_name">
              <Link to={{ pathname: '/resume', state:{setSelectedSection:'Skills'} }} >Skills</Link>
            </span>
          </li>
        </ul>
            

      </div>
    </>
  );
}

export default Shawn;