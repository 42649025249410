import React, { useRef, useEffect } from 'react';
import './css/particalcanvas.css';

function ParticleCanvas() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const colors = ['rgba(255, 140, 0, 0.5)', 'rgba(57, 155, 20, 0.5)', 'rgba(0, 202, 255, 0.5)'];
    let width = window.innerWidth;
    let height = window.innerHeight;

    canvas.width = width;
    canvas.height = height;

    // Set up particles
    const particles = [];
    const particleCount = 30;

    for (let i = 0; i < particleCount; i++) {
      particles.push(new Particle());
    }

    // Particle class
    function Particle() {
      this.x = Math.random() * width;
      this.y = Math.random() * height;
      this.radius = Math.random() * 10 + 5;
      this.speed = (Math.random() * 2 + 1) * 0.12;
      this.color = colors[Math.floor(Math.random() * colors.length)];
      this.alpha = 0.7;
      this.direction = -1; // Always move particles upwards
    }

   

    Particle.prototype.draw = function() {
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
      ctx.fillStyle = this.color;
      ctx.globalAlpha = this.alpha;
      ctx.fill();
    };

    Particle.prototype.update = function() {
      this.y += this.speed * this.direction; // Update particle position

      if (this.y < -this.radius) {
        this.y = height + this.radius;
      }
    };

    // Draw particles
    function drawParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach(particle => {
        particle.draw();
        particle.update();
      });

      requestAnimationFrame(drawParticles);
    }

    drawParticles();

    // Resize event listener
    function handleResize() {
      width = window.innerWidth;
      height = window.innerHeight;

      canvas.width = width;
      canvas.height = height;
    }

    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(drawParticles);
    };
  }, []);

  return <canvas ref={canvasRef} id="particle-canvas" />;
}

export default ParticleCanvas;
