import React from 'react';
import {Link} from 'react-router-dom';
import './css/call-to-action.css';
import PictureCanvas from './picture-canvas';

function CallToAction() {

  return (
    <>
      <div id='call-to-action-container'>      
        <div id='web-dev-future'>
          <h2>The <span className='primary-color' >Web Developer</span></h2>
          <h2> of the <span className='primary-color' >Future</span></h2>
          <p>You don't need just another web developer, 
           you need someone who is ready for the future.</p>
          <p> Partner with me, and let's build
          digital solutions that are not just relevant for today but resilient for tomorrow.
          </p>
          <Link to='/contact'><button className='contact'>Contact</button></Link>
        </div>
        <div id='web-developer-image-container'>
          <PictureCanvas />
        </div>
      </div>
    </>
  );
}

export default CallToAction;