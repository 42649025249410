import './css/resume.css';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';



function Resume() {

  const [selectedSection, setSelectedSection] = useState('Skills');

  const handleSectionSelect = (section) => {
    setSelectedSection(section);
  };

  return (
    <div className='resume-container'>
      <div className='menu'>
        <ul>
          <li
            className={selectedSection === 'Skills' ? 'active' : ''}
            onClick={() => handleSectionSelect('Skills')}
          >
            <i className="fas fa-code primary-color"></i>Skills
          </li>
          <li
            className={selectedSection === 'Education' ? 'active' : ''}
            onClick={() => handleSectionSelect('Education')}
          >
            <i className="fas fa-graduation-cap secondary-color"></i>Education
          </li>
          <li
            className={selectedSection === 'Work Experience' ? 'active' : ''}
            onClick={() => handleSectionSelect('Work Experience')}
          >
            <i className="fas fa-briefcase brown"></i> Work Experience
          </li>

          <li
            className={selectedSection === 'Certifications' ? 'active' : ''}
            onClick={() => handleSectionSelect('Certifications')}
          >
            <i className="fas fa-certificate tertiary-color"></i> Certifications
          </li>
          <li
            className={selectedSection === 'Courses' ? 'active' : ''}
            onClick={() => handleSectionSelect('Courses')}
          >
            <i className="fas fa-book brown"></i> Courses
          </li>
          <li
            className={selectedSection === 'Languages' ? 'active' : ''}
            onClick={() => handleSectionSelect('Languages')}
          >
            <i className="fas fa-globe tertiary-color"></i> Languages
          </li>

          <li
            className={selectedSection === 'References' ? 'active' : ''}
            onClick={() => handleSectionSelect('References')}
          >
            <i className="fas fa-share-alt secondary-color"></i> References
          </li>
        </ul>
      </div>

      <div className="resume-content">
        {selectedSection === 'Education' && (
          <div>
            <h4><i className="fas fa-graduation-cap secondary-color"></i>Education</h4>
            <p className="primary-text no-margin top">Omnia Upseerinkatu</p>
            <p className="secondary-text no-margin">Full Stack Web Development - Finnish Vocational Degree ICT</p>
            <p className="primary-text no-margin top">Lärkkulla Stiftelsen</p>
            <p className="secondary-text no-margin">Swedish Studies and Exam</p>
            <p className="primary-text no-margin top">Wilmer Hutchins Highschool</p>
            <p className="secondary-text no-margin">High School Diploma - Top 10% Honors</p>
          </div>
        )}

        {selectedSection === 'Work Experience' && (
          <div>
            <h4><i className="fas fa-briefcase secondary-color"></i>Work Experience</h4>
            <div>
              <div className="primary-color"><strong>Drupal Developer with Ceriumsoft LLC</strong></div>
              <div className="work-date tertiary-color">August 2015 - March 2021</div>
              <ul className="secondary-text">
                <li>Frontend design</li>
                <li>Backend development and maintenance</li>
                <li>Setup, monitor, and maintain servers</li>
                <li>Security updates and patches</li>
                <li>Managed mySQL databases</li>
                <li>Developed custom modules tailored to clients</li>
                <li>Used feed modual for mass data migration to new Drupal versions</li>
              </ul>
            </div>

            <div>
              <div className='primary-color'><strong>Data Entry with JP Morgan Chase</strong></div>
              <div className='work-date tertiary-color'>June 2008 - August 2015</div>
              <ul className='secondary-text'>
                <li>Updated parameters into credit-card terminals</li>
                <li>Managed multiple software systems</li>
                <li>Communicated with clients and handled tickets</li>
              </ul>
            </div>
          </div>
        )}

        {selectedSection === 'Certifications' && (
          <div>
            <h4><i className="fas fa-certificate tertiary-color"></i><span className="tertiary-color">Certifications</span></h4>
            <div className=''>CompTIA Security+ Certified</div>
          </div>
        )}

        {selectedSection === 'Courses' && (
          <div>
            <h4><i className="fas fa-book brown"></i>Courses</h4>
            <div className='work-date tertiary-color course-date'>August 2023 - May 2024</div>
            <div>Full Stack Web Development - Finnish Vocational Degree ICT</div>
            <div className='work-date tertiary-color course-date'>March 2023 - April 2023</div>
            <div>React - The Complete Guide 2023</div>
          </div>
        )}

        {selectedSection === 'Languages' && (
          <div>
            <h4 id="resume-languages"><i className="fas fa-globe tertiary-color"></i>Languages</h4>
            <table className="language-table secondary-text">
              <thead>
                <tr>
                  <th className='tertiary-color'>Proficiency</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>English</td>
                  <td>Mother Tongue</td>
                </tr>
                <tr>
                  <td>Swedish</td>
                  <td>Level C</td>
                </tr>
                <tr>
                  <td>Finnish</td>
                  <td>No</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {selectedSection === 'Skills' && (
          <div>
            <h3 className='h3-skills'>Skills <i className="fas fa-code primary-color"></i></h3>
            <div className='skills-container'>          
              <div className='skills-mini-container'>
                <h4>Front End</h4>
                <ul className='secondary-text'>
                  <li>HTML 5</li>
                  <li>CSS3 | SCSS</li>
                  <li>JAVASCRIPT | TYPESCRIPT</li>
                  <li>BOOTSTRAP</li>
                  <li>JQUERY</li>
                </ul>
              </div>
              <div className='skills-mini-container'>
                <h4>Back End</h4>
                <ul className='secondary-text'>
                  <li>NODE.JS</li>
                  <li>DENO</li>
                  <li>MYSQL</li>
                  <li>PHP</li>
                  <li>PYTHON</li>
                  <li>JAVA</li>
                </ul>
              </div>
              <div className='skills-mini-container'>
                <h4>Frameworks</h4>
                <ul className='secondary-text'>
                  <li>REACT</li>
                  <li>DOCKER</li>
                  <li>DRUPAL</li>
                  <li>GIT</li>
                  <li>LINUX-CLI</li>
                  <li>NGINX</li>
                  <li>APACHE</li>
                  <li>ETA [Template Engine]</li>

                </ul>    
              </div>
            </div>
          </div>
        )}

        {selectedSection === 'References' && (
          <div>
            <h4> <i className="fas fa-share-alt secondary-color"></i>References</h4>
            <table className='reference-table secondary-text'>
              <thead>
                <tr>
                  <th className='tertiary-color'>Position</th>
                  <th className='secondary-color'>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Teacher</td>
                  <td>petteri.lappalainen@omnia.fi</td>
                </tr>
                <tr>
                  <td>Colleague</td>
                  <td>erdem.tas@edu.omnia.fi</td>
                </tr>
                <tr>
                  <td>Employer</td>
                  <td>mike@ceriumsoft.com</td>
                </tr>
              </tbody>       
            </table>
            {/* 
            <p className='primary-text'>Teacher</p>
            <div>petteri.lappalainen@omnia.fi</div>
            <div>erdem.tas@edu.omnia.fi</div>
            <div>mike@ceriumsoft.com</div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default Resume;

