import Navigation from '../components/navigation';
import CallToAction from '../components/call-to-action';
import Footer from '../components/footer';
import ParticleCanvas2 from '../components/particlecanvas2';

function HomePage() {
  return(
    <div className="App">
      <header className="main-page main-page-organizer">
        <ParticleCanvas2 />
        <Navigation />
        <CallToAction />
        <Footer />
      </header>
    </div>
  );
}

export default HomePage;