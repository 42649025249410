import MyProjects from '../components/myprojects';
import Navigation from '../components/navigation';
import Footer from '../components/footer';

function ProjectsPage() {
  return(
    <div className="App">
      <header className="baseline">
        <Navigation />
        <MyProjects />
        <Footer />
      </header>
    </div>
  );
}

export default ProjectsPage;