import './css/myintroduction.css';

function MyIntroduction(){
    
  return (
    <>
      <div className="introduction-container">
        <h2>Hi, I'm <span className="primary-color">Shaun</span></h2>
        <p className="secondary-text">Hey there, I'm Shaun Musick, a full stack developer originally from Dallas, Texas. From a young age, I had a passion for technology and computers. I loved tinkering with gadgets and software, trying to figure out how everything worked.</p>
        <p className="secondary-text">After high school, I decided not to go to college due to the high debt cost and instead, I started teaching myself how to code. I spent countless hours learning various programming languages myself and building my skills through projects.</p>
        <p className="secondary-text">In 2016, I decided to move to Finland to be with my wife, and I've been living here ever since. While I love the Finnish culture and lifestyle, finding employment as a self-taught full stack developer in a new country has been challenging. I'm currently on the lookout for new job opportunities and am excited about what the future holds.</p>
        <p className="secondary-text">Throughout my career, I've worked on a variety of projects, ranging from website design to mobile app development. One of the things I love most about being a full stack developer is the ability to work on both the front end and back end of projects, giving me a deep understanding of how everything works together.</p>
        <p className="secondary-text">Looking back on my journey so far, I'm proud of the skills and experience I've built up over the years. As I continue to search for employment opportunities in Finland, I'm excited to see where my skills and passion for technology will take me next. If you're interested in working with me then feel free to reach out. I'm always open to new connections and opportunities.</p>
      </div>
    </>
  );
}

export default MyIntroduction;