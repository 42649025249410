import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import HomePage from './routes/home';
import ProjectsPage from './routes/projects';
import ResumePage from './routes/resume';
import AboutPage from './routes/about';
import ContactPage from './routes/contact';


const myRoutes = createBrowserRouter([
  { path: '/',         element: <HomePage />     },
  { path: '/projects', element: <ProjectsPage /> },
  { path: '/resume',   element: <ResumePage />   },
  { path: '/about',    element: <AboutPage />    },
  { path: '/contact',  element: <ContactPage />  },
]);

function App() {
  
  return (
    <RouterProvider router={myRoutes} />
  );
}

export default App;
